<template>
  <div style="height: 100%">
    <div style="height: 40px"></div>
    <div class="opeDashboard">
      <el-row :gutter="24">
        <!-- 基本信息 -->
        <el-col :span="6">
          <div class="opeDashboard-msg-bottom-people bg">
            <div>
              <p>住宅总人数（人）</p>
              <p><i class="el-icon-caret-bottom"></i></p>
              <p>{{ areabaseData.sqzrs || 0 }}</p>
            </div>
            <div>
              <p>住宅总房屋数（套）</p>
              <p><i class="el-icon-caret-bottom"></i></p>
              <p>{{ areabaseData.zfws || 0 }}</p>
            </div>
          </div></el-col
        >
        <!-- 社区画像 -->
        <el-col :span="9">
          <div class="opeDashboard-portrait opeDashboard-common bg">
            <el-tooltip
              effect="dark"
              content="社区画像：展示社区各个年龄段人数"
              placement="right-end"
            >
              <div class="tipTitle">
                <span>社区画像</span><img src="./img/tip.png" alt="" />
              </div>
            </el-tooltip>
            <div id="oPortrait"></div>
          </div>
        </el-col>
        <!-- 人房数据 -->
        <el-col :span="9">
          <div class="opeDashboard-areaPeopleRoom opeDashboard-common bg">
            <el-tooltip effect="dark" placement="right-end">
              <div slot="content">
                人房匹配率:基础数据中，有人员信息的房屋数/总房屋数<br />注册房屋覆盖率:基础数据中，有注册用户的房屋数/总房屋数
              </div>
              <div class="tipTitle">
                <span>人房数据</span><img src="./img/tip.png" alt="" />
              </div>
            </el-tooltip>
            <div id="areaPeopleRoom"></div>
          </div>
        </el-col>
      </el-row>

      <!-- 使用情况 -->
      <el-row :gutter="20" class="mt20">
        <!-- 居民端使用人数 -->
        <el-col :span="9">
          <div class="opeDashboard-useNumber opeDashboard-common bg">
            <el-tooltip effect="dark" placement="right-end">
              <div slot="content">
                注册人数:居民端注册人数<br />游客人数:居民端注册在游客房号下的人数
              </div>
              <div class="tipTitle">
                <span>居民端使用人数</span><img src="./img/tip.png" alt="" />
              </div>
            </el-tooltip>
            <div id="useNumberEchart"></div>
          </div>
        </el-col>
        <el-col :span="9">
          <div class="opeDashboard-addPeople opeDashboard-common bg">
            <div class="title">
              <el-tooltip effect="dark" placement="right-end">
                <div slot="content">新增注册人数:居民端新增的注册人数</div>
                <div class="tipTitle">
                  <span>新增注册人数</span><img src="./img/tip.png" alt="" />
                </div>
              </el-tooltip>
              <v-select
                :clearable="false"
                :options="timeSelectOptions"
                v-model="searchParam.addPeopleSelect"
                @change="addPeopleSelOnChange"
              />
            </div>
            <div id="addPeopleEchart"></div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="opeDashboard-thirtyActivation opeDashboard-common bg">
            <el-tooltip effect="dark" placement="right-end">
              <div slot="content">
                近30日活跃率:近30日中，活跃总人数(不重复计算)/总注册人数
              </div>
              <div class="tipTitle">
                <span>近30日活跃度</span><img src="./img/tip.png" alt="" />
              </div>
            </el-tooltip>
            <div id="thirtyActivation"></div>
          </div>
        </el-col>
      </el-row>

      <!-- 活跃沉默统计 -->
      <el-row :gutter="20" class="mt20">
        <!-- 活跃人数 -->
        <el-col :span="16">
          <div class="opeDashboard-hyrs opeDashboard-common bg">
            <div class="title">
              <el-tooltip effect="dark" placement="right-end">
                <div slot="content">活跃人数:登录过居民端的用户</div>
                <div class="tipTitle">
                  <span>活跃人数</span><img src="./img/tip.png" alt="" />
                </div>
              </el-tooltip>
              <v-select
                :clearable="false"
                :options="timeSelectOptions"
                v-model="searchParam.activePeopleSelect"
                @change="activePeopleSelOnChange"
              />
            </div>
            <div class="opeDashboard-hyrs-echarts">
              <div id="hyrsLine"></div>
            </div>
          </div>
        </el-col>
        <!-- 沉默人数 -->
        <el-col :span="8">
          <div class="opeDashboard-cmrs opeDashboard-common bg">
            <el-tooltip effect="dark" placement="right-end">
              <div slot="content">
                超过90日的沉默人数:超过90天没有日活数据的注册人数
              </div>
              <div class="tipTitle">
                <span>超过90日沉默人数</span><img src="./img/tip.png" alt="" />
              </div>
            </el-tooltip>
            <div id="silentPie"></div>
          </div>
        </el-col>
      </el-row>

      <!-- 日活统计 点击排行 -->
      <el-row :gutter="20" class="mt20">
        <!-- 日活统计 -->
        <el-col :span="15">
          <div class="opeDashboard-rhtj opeDashboard-common bg">
            <div class="title">
              <el-tooltip effect="dark" placement="right-end">
                <div slot="content">点击量:应用点击量</div>
                <div class="tipTitle">
                  <span>近30日日活统计</span><img src="./img/tip.png" alt="" />
                </div>
              </el-tooltip>
              <v-select
                :clearable="false"
                :options="tenantIdOptions"
                v-model="searchParam.tenantId"
                @change="rhtjLineChange"
              />
            </div>
            <div id="rhtjLine"></div>
          </div>
        </el-col>
        <!-- 点击排行 -->
        <el-col :span="9">
          <div class="opeDashboard-djph opeDashboard-common bg">
            <el-tooltip effect="dark" placement="right-end">
              <div slot="content">
                近30日应用点击排行(前五)<br />近30日应用点击量总数排行前五
              </div>
              <div class="tipTitle">
                <span>近30日应用点击排行（前五）</span
                ><img src="./img/tip.png" alt="" />
              </div>
            </el-tooltip>
            <div id="djph"></div>
          </div>
        </el-col>
      </el-row>

      <!-- 商品 积分信息 -->
      <el-row :gutter="20" class="mt20">
        <el-col :span="10">
          <div class="opeDashboard-hxl opeDashboard-common bg">
            <div class="title">
              <el-tooltip effect="dark" placement="right-end">
                <div slot="content">
                  积分订单核销率:总核销订单数/总兑换订单数
                </div>
                <div class="tipTitle">
                  <span>积分订单核销率</span><img src="./img/tip.png" alt="" />
                </div>
              </el-tooltip>
              <v-select
                :clearable="false"
                :options="timeSelectOptions"
                v-model="searchParam.hxlValue"
                @change="handleHxlChange"
                :width="100"
              />
            </div>
            <div id="hxlPie"></div>
          </div>
        </el-col>
        <el-col :span="9">
          <div class="opeDashboard-xfl opeDashboard-common bg">
            <div class="title">
              <el-tooltip effect="dark" placement="right-end">
                <div slot="content">积分消费率:产出积分/消耗积分</div>
                <div class="tipTitle">
                  <span>积分消费率</span><img src="./img/tip.png" alt="" />
                </div>
              </el-tooltip>
              <v-select
                :clearable="false"
                :options="timeSelectOptions"
                v-model="searchParam.xflValue"
                :width="100"
                @change="handleXflChange"
              />
            </div>

            <div id="xflPie"></div>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="opeDashboard-hdtj opeDashboard-common bg">
            <div class="title">
              <el-tooltip effect="dark" placement="right-end">
                <div slot="content">
                  <p>活动数:活动开始时间和结束时间在近30日之内的活动数量</p>
                  活动参与人次:活动开始时间和结束时间在近30日之内的活动报名人次
                </div>
                <div class="tipTitle">
                  <span>近30日活动统计</span><img src="./img/tip.png" alt="" />
                </div>
              </el-tooltip>
              <v-select
                :clearable="false"
                :options="tenantIdOptions"
                v-model="searchParam.hdtjSelectValue"
                @change="handleHdtjChange"
              />
            </div>

            <div class="opeDashboard-hdtj-content">
              <div class="opeDashboard-hdtj-content-top">
                <div>
                  <p>活动数</p>
                  <p>{{ activityObj.activityCount }}<span>场</span></p>
                </div>
              </div>
              <div class="opeDashboard-hdtj-content-bottom">
                <div>
                  <p>活动参与人次</p>
                  <p>
                    {{ activityObj.joinCount ? activityObj.joinCount : 0
                    }}<span>人次</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <!-- 空间预约 -->
      <el-row :gutter="20" class="mt20">
        <el-col :span="11">
          <div class="opeDashboard-yycs opeDashboard-common bg">
            <div class="title">
              <span>空间预约次数</span>
              <v-select
                :clearable="false"
                :options="timeSelectOptions"
                v-model="searchParam.kjyycs"
                @change="kjyycsSelOnChange"
              />
            </div>
            <div id="yycsLine"></div>
          </div>
        </el-col>
        <el-col :span="9">
          <div class="opeDashboard-pmqs opeDashboard-common bg">
            <div class="title">近30日空间预约排名</div>

            <div id="pmqs"></div>
          </div>
        </el-col>

        <el-col :span="4">
          <div class="opeDashboard-yykjs">
            <div class="title">
              <span>可预约空间数</span>
              <v-select
                :clearable="false"
                :options="tenantIdOptions"
                v-model="searchParam.kjyycstenantId"
                @change="yykjsChange"
              />
            </div>
            <p>{{ canPlaceOrderObj }}个</p>
          </div>
        </el-col>
      </el-row>
      <!-- 最后一层 -->
      <el-row :gutter="20" class="mt20">
        <el-col :span="9">
          <div class="opeDashboard-sjsb opeDashboard-common bg">
            <div class="title">事件上报完成率</div>
            <div id="sjsbPie"></div>
          </div>
        </el-col>

        <el-col :span="9">
          <div class="opeDashboard-wcl opeDashboard-common bg">
            <el-tooltip effect="dark" placement="right-end">
              <div slot="content">设备离线数:当前社区物联网离线设备数量</div>
              <div class="tipTitle">
                <span>设备离线数</span><img src="./img/tip.png" alt="" />
              </div>
            </el-tooltip>
            <div id="wclPie"></div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="opeDashboard-sbqk opeDashboard-common bg">
            <div class="title">
              <el-tooltip effect="dark" placement="right-end">
                <div slot="content">
                  设备在线率:社区物联网离线设备数量/社区物联网设备总数
                </div>
                <div class="tipTitle">
                  <span>设备在线率</span><img src="./img/tip.png" alt="" />
                </div>
              </el-tooltip>
              <v-select
                :clearable="false"
                :options="tenantIdOptions"
                v-model="searchParam.sbqktenantId"
                @change="sbqkChange"
              />
            </div>
            <div class="opeDashboard-sbqk-content">
              <div id="sbqkPie"></div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  drawAreahyrsLineChart,
  drawThirtyActivationChart,
  drawArearegisterNumberChart,
  drawAreaUseNumberChart,
  drawCommunityPeopleRoom,
  drawCommunityPortrait,
  drawAreaSilentChart,
  drawHengChart,
} from "./areaEchart";
import { drawDjph, drawSdtPie } from "./echart";
import { debounce } from "@/utils/utils";
import {
  getJfddhxlDistrictUrl,
  getSqhxDetailsDistrictUrl,
  getDeviceOnlinelDistrictUrl,
  getPlaceOrderPlFiveDistrictUrl,
  getPlaceOrderDetailsDistrictUrl,
  getActivityDetailsDistrictUrl,
  getCanPlaceOrderDistrictUrl,
  getPointXflDistrictUrl,
  getSingleStatisticsDistrictUrl,
  getNewAddPersonDataDistrictUrl,
  getDailyActivityDetailsDistrictUrl,
  getWlsqTenantInfoUrl,
  getHyPersonsDistrictUrl,
  getFirstYydjphDetailsDistrictUrl,
} from "./api.js";
import { ageArrMap } from "./map.js";
import { arrPortrait } from "./portrait.js";
// import countTo from "vue-count-to";
// import moment from "moment";
export default {
  name: "runCondition",
  data() {
    return {
      hjrks: "", //户均人口数
      areabaseData: {
        sqzrs: 0,
        zfws: 0,
      },
      searchParam: {
        addPeopleSelect: "month",
        activePeopleSelect: "month",
        kjyycs: "month",
        addPeopleTime: 1,
        hdtjSelectValue: "",
        hxlValue: "month",
        xflValue: "month",
        tenantId: "",
        kjyycstenantId: "",
        sbqktenantId: "",
      },
      timeSelectOptions: [
        { label: "近30日", value: "month" },
        { label: "本年度", value: "year" },
      ],
      activityObj: {}, //活动统计
      canPlaceOrderObj: "", // 可预约空间数量
      tenantIdOptions: [],
      PlaceOrderPlThreeData: [],
      kjyycsData: [],
      oData: [],
      SqhxDetailsDistrictData: [],
      JfddhxlData: [],
      PointXflData: [],
      DeviceOnlinelDistrictData: 0,
      addPeopleNumberData: [],
      thirtyActivationData: [],
      DailyActivityDetailsDistrictData: [],
      portraitEchart: "", //区级社区画像
      areaPeopleRoomEchart: "", //区级人房数据
      addPeopleEchart: "", //区级新增注册
      thirtyActivationEchart: "", //区级近30日活跃度
      DaliyChart: "", //区级近30日活
      useNumberChart: "", //居民端使用人数饼图
      sjsbPieChart: "", //事件上报
      wclPieChart: "", //设备离线
      cmlPieChart: "", //沉默率
      sbqkChart: "", //设备情况
      djphChart: "", // 点击排行
      pmqsChart: "", //排名前三
      hxlChart: "", //核销率
      xflChart: "", //消费率
      communityGenderChart: "", //性别图
      addPeopleChart: "", //新增人数
      hyrsChart: "", //活跃人数
      rhtjChart: "", //日活点击
      yycsChart: "", // 预约次数
      sbqkPieChart: "",
    };
  },
  components: {
    // countTo,
  },
  async mounted() {
    await this.gettenantId();
    this.getSingleStatisticsDistrict();
    //区级新增注册人数
    this.getAddPeopleNumberData();
    //区级社区画像
    this.getSqhxDetailsDistrict();
    this.getUseNumber();
    //区域近30日活跃度
    this.getHyPersonsDistrict();
    //区级获取近30日日活统计详细数据
    this.getDailyActivityDetailsDistrict();
    // 获取区级订单核销率
    this.getJfddhxl();
    // 获取区级消费率
    this.getPointXfl();
    // 获取区级活动统计数据
    this.getActivityData();
    // 获取区级空间预约数
    this.getCanPlaceOrder();
    //获取区级空间预约次数
    this.getkjyycsData();
    // 获取空间预约次数排行
    this.getPlaceOrderPlThree();
    // 获取区级近30日点击排行列表
    this.getDjphList();
    this.getDeviceOnlinelDistrict();
  },
  methods: {
    async gettenantId() {
      this.tenantIdOptions = [];
      const res = await this.$axios.get(getWlsqTenantInfoUrl, {
        params: {},
      });
      if (res.code === 200 && res.success) {
        res.data.forEach((ele) => {
          this.tenantIdOptions.push({
            label: ele.tenantName,
            value: ele.tenantId,
          });
        });
        this.tenantIdOptions.unshift({
          label: "所有社区",
          value: "",
        });
      }
    },
    async getDeviceOnlinelDistrict() {
      const res = await this.$axios.get(getDeviceOnlinelDistrictUrl, {
        params: {
          tenantId: this.searchParam.sbqktenantId,
        },
      });
      if (res.code === 200 && res.success) {
        this.DeviceOnlinelDistrictData = res.data;
        this.drawsbqk();
      }
    },
    drawsbqk() {
      let _this = this;
      drawSdtPie("sbqkPie", this.DeviceOnlinelDistrictData);
      if (!this.sbqkPieChart) {
        this.sbqkPieChart = debounce(() => {
          drawSdtPie("sbqkPie", this.DeviceOnlinelDistrictData).resize();
        }, 100);
        window.addEventListener("resize", _this.sbqkPieChart, true);
      }
    },
    sbqkChange() {
      this.getDeviceOnlinelDistrict();
    },
    rhtjLineChange() {
      this.getDailyActivityDetailsDistrict();
    },
    async getDailyActivityDetailsDistrict() {
      let _this = this;
      let params = {
        tenantId: this.searchParam.tenantId,
      };
      const res = await this.$axios.get(getDailyActivityDetailsDistrictUrl, {
        params,
      });
      if (res.success && res.code === 200) {
        this.DailyActivityDetailsDistrictData = res.data;
        this.getDaliyChart();
      }
    },
    getDaliyChart() {
      let _this = this;
      let obj = { x: [], y: [] };
      this.DailyActivityDetailsDistrictData.forEach((ele) => {
        obj.x.push(ele.date);
        obj.y.push(ele.count);
      });
      let seriesParams = {
        name: "次",
      };
      this.$nextTick(() => {
        drawThirtyActivationChart("rhtjLine", obj, seriesParams);
        if (!this.DaliyChart) {
          this.DaliyChart = debounce(() => {
            drawThirtyActivationChart("rhtjLine", obj, seriesParams).resize();
          }, 100);
          window.addEventListener("resize", _this.DaliyChart, true);
        }
      });
    },
    async getSilentPieData() {
      let _this = this;
      let obj = {
        y: [],
      };
      this.oData.forEach((ele) => {
        obj.y.push({
          value: ele.silentNumber,
          name: ele.tenantName,
        });
      });
      this.$nextTick(() => {
        drawAreaSilentChart("silentPie", obj);
        this.cmlPieChart = debounce(() => {
          drawAreaSilentChart("silentPie", obj).resize();
        }, 100);
        window.addEventListener("resize", _this.cmlPieChart, true);
      });
    },
    // 活跃人数选中变化
    activePeopleSelOnChange(value) {
      this.getHyPersonsDistrict();
    },
    async getHyPersonsDistrict() {
      let _this = this;
      let params = {
        selectLatitude: this.searchParam.activePeopleSelect,
      };
      const res = await this.$axios.get(getHyPersonsDistrictUrl, {
        params,
      });
      if (res.success && res.code === 200) {
        this.thirtyActivationData = res.data;
        this.drawHyPersonsDistrict();
      }
    },
    drawHyPersonsDistrict() {
      let _this = this;
      let obj = {
        x: [],
        y: [],
      };
      this.thirtyActivationData.forEach((ele, index) => {
        let oobj = {
          name: ele.tenantName,
          type: "line",
          data: [],
        };
        if (index == 0) {
          ele.list &&
            ele.list.forEach((Element) => {
              obj.x.push(Element.date);
            });
        }
        ele.list &&
          ele.list.forEach((Element) => {
            oobj.data.push(Element.count);
          });
        obj.y.push(oobj);
      });
      this.$nextTick(() => {
        drawAreahyrsLineChart("hyrsLine", obj);
        if (!this.hyrsChart) {
          this.hyrsChart = debounce(() => {
            drawAreahyrsLineChart("hyrsLine", obj).resize();
          }, 100);
          window.addEventListener("resize", _this.hyrsChart, true);
        }
      });
    },
    async getThirtyActivation() {
      let _this = this;
      let obj = { x: [], y: [] };
      this.oData.forEach((ele) => {
        obj.x.push(ele.tenantName);
        obj.y.push(_this.filterUnit(ele.activeRate));
      });
      let seriesParams = {
        name: "",
        axisLabelFormatter: "{value}%",
        valueFormatter(value) {
          return value + "%";
        },
        barWidth: "30%",
        yAxisFommat: {
          splitNumber: 5,
          max: 100,
        },
      };
      this.$nextTick(() => {
        drawThirtyActivationChart("thirtyActivation", obj, seriesParams);
        if (!this.thirtyActivationEchart) {
          this.thirtyActivationEchart = debounce(() => {
            drawThirtyActivationChart(
              "thirtyActivation",
              obj,
              seriesParams
            ).resize();
          }, 100);
          window.addEventListener("resize", _this.thirtyActivationEchart, true);
        }
      });
    },
    // 新增人数选中变化
    addPeopleSelOnChange(value) {
      this.getAddPeopleNumberData();
    },
    async getAddPeopleNumberData() {
      let _this = this;
      let params = {
        selectLatitude: this.searchParam.addPeopleSelect,
      };
      const res = await this.$axios.get(getNewAddPersonDataDistrictUrl, {
        params,
      });
      if (res.success && res.code === 200) {
        this.addPeopleNumberData = res.data;
        this.addPeopleNumber();
      }
    },
    //新增注册人数
    addPeopleNumber() {
      let _this = this;
      this.$nextTick(() => {
        let arr = [];
        this.addPeopleNumberData.forEach((ele) => {
          arr.push({
            value: ele.count,
            name: ele.tenantName,
          });
        });
        drawArearegisterNumberChart("addPeopleEchart", arr);
        this.addPeopleEchart = debounce(() => {
          drawArearegisterNumberChart("addPeopleEchart", arr).resize();
        }, 100);
        window.addEventListener("resize", _this.addPeopleEchart, true);
      });
    },
    async getSingleStatisticsDistrict() {
      //区级看板基础数据
      let _this = this;
      const res = await this.$axios.get(getSingleStatisticsDistrictUrl, {});
      if (res.success && res.code === 200) {
        this.oData = res.data;
        this.areabaseData.sqzrs = 0;
        this.areabaseData.zfws = 0;
        this.oData.forEach((ele) => {
          this.areabaseData.sqzrs += Number(ele.tenantAllPersonCount);
          this.areabaseData.zfws += Number(ele.allRoomCount);
        });
        this.getUseNumber();
        this.getSilentPieData();
        this.getThirtyActivation();
        // 获取人房户均人口数据
        this.getHjrks();
        this.drawUploadFinish();
        this.drawoffline();
      }
    },
    drawoffline() {
      let _this = this;
      // 设备离线
      let obj = {
        x: [],
        y: [],
      };
      let seriesParams = {
        seriesColor: "#FF8C8C",
        barWidth: "20%",
      };
      this.oData.forEach((ele) => {
        obj.x.push(ele.tenantName);
        obj.y.push(ele.deviceOfflineCount ? ele.deviceOfflineCount : 0);
      });
      drawThirtyActivationChart("wclPie", obj, seriesParams);
      if (!this.wclPieChart) {
        this.wclPieChart = debounce(() => {
          drawThirtyActivationChart("wclPie", obj, seriesParams).resize();
        }, 100);
        window.addEventListener("resize", _this.wclPieChart, true);
      }
    },
    drawUploadFinish() {
      let _this = this;
      let obj = {
        x: [],
        y1: [],
        y2: [],
      };
      this.oData.forEach((ele) => {
        let uni = 0;
        obj.x.push(ele.tenantName);
        let finshWorkInfoCountrat = 0;
        let unirat = 0;
        if (
          ele.finshWorkInfoCount &&
          ele.workInfoCount &&
          ele.workInfoCount != 0
        ) {
          finshWorkInfoCountrat =
            (
              Number(ele.finshWorkInfoCount) / Number(ele.workInfoCount)
            ).toFixed(2) * 100;
        }
        obj.y1.push(finshWorkInfoCountrat);
        if (ele.workInfoCount && ele.workInfoCount != 0) {
          uni = Number(ele.workInfoCount) - Number(ele.finshWorkInfoCount);
          unirat = (uni / Number(ele.workInfoCount)).toFixed(2) * 100;
        }
        obj.y2.push(unirat);
      });
      let seriesParams = {
        name1: "事件上报完成率",
        name2: "事件上报未完成率",
        axisLabelFormatter: "{value}%",
        valueFormatter(value) {
          return value + "%";
        },
      };
      drawAreaUseNumberChart("sjsbPie", obj, seriesParams);
      this.sjsbPieChart = debounce(() => {
        drawAreaUseNumberChart("sjsbPie", obj, seriesParams).resize();
      }, 100);
      window.addEventListener("resize", _this.sjsbPieChart, true);
    },
    async getNewAddPersonDataDistrict() {
      let _this = this;
      const res = await this.$axios.get(getNewAddPersonDataDistrictUrl, {});
      if (res.success && res.code === 200) {
        this.oData = res.data;
        this.getUseNumber();
      }
    },
    // 居民端用户数据
    async getUseNumber() {
      let _this = this;
      let obj = {
        x: [],
        y1: [],
        y2: [],
      };
      this.oData.forEach((ele) => {
        obj.x.push(ele.tenantName);
        obj.y1.push(ele.zcrs);
        obj.y2.push(ele.yks);
      });
      let seriesParams = {
        name1: "注册人数",
        name2: "游客人数",
      };
      drawAreaUseNumberChart("useNumberEchart", obj, seriesParams);
      this.useNumberChart = debounce(() => {
        drawAreaUseNumberChart("useNumberEchart", obj, seriesParams).resize();
      }, 100);
      window.addEventListener("resize", _this.useNumberChart, true);
    },
    // 人房数据
    async getHjrks() {
      let _this = this;
      let obj = {
        x: [],
        y1: [],
        y2: [],
      };
      let seriesParams = {
        axisLabelFormatter: "{value}%",
        valueFormatter(value) {
          return value + "%";
        },
      };
      this.oData.forEach((ele) => {
        obj.x.push(ele.tenantName);
        obj.y1.push(_this.filterUnit(ele.registerRoomMatchingRate));
        obj.y2.push(_this.filterUnit(ele.personRoomPenetrationMatchingRate));
      });

      drawCommunityPeopleRoom("areaPeopleRoom", obj, seriesParams);
      this.areaPeopleRoomEchart = debounce(() => {
        drawCommunityPeopleRoom("areaPeopleRoom", obj, seriesParams).resize();
      }, 100);
      window.addEventListener("resize", _this.areaPeopleRoomEchart, true);
    },
    async getSqhxDetailsDistrict() {
      const res = await this.$axios.get(getSqhxDetailsDistrictUrl);
      if (res.code === 200 && res.success) {
        this.SqhxDetailsDistrictData = res.data;
        this.getPortraitData();
      }
    },
    //社区画像
    async getPortraitData() {
      let _this = this;
      let aPrr = JSON.parse(JSON.stringify(arrPortrait));
      let obj = {
        x: [],
        y: [],
      };
      //补齐数据
      this.SqhxDetailsDistrictData.forEach((ele) => {
        ele.arr = [];
        if (ele.list) {
          ele.list.forEach((Element) => {
            ele.arr.push(Element.data);
          });
        }
      });
      let oArr = ["1", "2", "3", "4", "5", "6"];
      oArr.forEach((i) => {
        this.SqhxDetailsDistrictData.forEach((ele) => {
          if (ele.arr.indexOf(i) == -1) {
            ele.list.push({
              data: i,
              count: 0,
            });
          }
        });
      });
      //排序
      this.SqhxDetailsDistrictData.forEach((ele) => {
        if (ele.list) {
          ele.list.sort((a, b) => Number(a.data) - Number(b.data));
        }
      });
      aPrr.forEach((oEle) => {
        this.SqhxDetailsDistrictData.forEach((ele) => {
          if (ele.list) {
            ele.list.forEach((Element) => {
              if (oEle.num == Element.data) {
                oEle.data.push(Element.count);
              }
            });
          }
        });
      });
      this.SqhxDetailsDistrictData.forEach((ele) => {
        obj.x.push(ele.tenantName);
      });
      obj.y = aPrr;
      console.log(obj, "0000=====");
      this.$nextTick(() => {
        drawCommunityPortrait("oPortrait", obj);
        this.portraitEchart = debounce(() => {
          drawCommunityPortrait("oPortrait", obj).resize();
        }, 100);
        window.addEventListener("resize", _this.portraitEchart, true);
      });
    },

    // 近30日应用点击排名
    async getDjphList() {
      let _this = this;
      const res = await this.$axios.get(getFirstYydjphDetailsDistrictUrl);
      if (res.code === 200 && res.success) {
        let yData = res.data.map((item) => {
          return item.placeName + "(" + item.tenantName + ")";
        });
        let seriesData = res.data.map((item) => {
          return item.count;
        });
        // 获取总值
        let sumList = [];
        for (let i in res.data) {
          sumList.push(res.data[0].count);
        }
        // 点击排行
        drawDjph("djph", yData, seriesData, sumList);
        this.djphChart = debounce(() => {
          drawDjph("djph", yData, seriesData, sumList).resize();
        }, 100);
        window.addEventListener("resize", _this.djphChart, true);
      }
    },

    async getActivityData() {
      const res = await this.$axios.get(getActivityDetailsDistrictUrl, {
        params: {
          tenantId: this.searchParam.hdtjSelectValue,
        },
      });
      if (res.code === 200 && res.success) {
        this.activityObj = JSON.parse(JSON.stringify(res.data[0]));
      }
    },

    handleHdtjChange() {
      this.getActivityData();
    },
    // 可预约空间数
    async getCanPlaceOrder() {
      const res = await this.$axios.get(getCanPlaceOrderDistrictUrl, {
        params: {
          tenantId: this.searchParam.kjyycstenantId,
        },
      });
      if (res.code === 200 && res.success) {
        this.canPlaceOrderObj = res.data;
      }
    },
    yykjsChange() {
      this.getCanPlaceOrder();
    },
    // 预约次数排行
    async getPlaceOrderPlThree() {
      let _this = this;
      const res = await this.$axios.get(getPlaceOrderPlFiveDistrictUrl);
      if (res.code === 200 && res.success) {
        this.PlaceOrderPlThreeData = res.data;
        this.drawPlaceOrderPlThree();
      }
    },
    drawPlaceOrderPlThree() {
      let _this = this;
      let obj = {
        x: [],
        y1: [],
        y2: [],
      };
      this.PlaceOrderPlThreeData.forEach((ele) => {
        obj.x.unshift(ele.placeName + "(" + ele.tenantName + ")");
        obj.y1.unshift(ele.count ? ele.count : 0);
        obj.y2.unshift(ele.count ? ele.count + "次" : 0 + "次");
      });
      drawHengChart("pmqs", obj);
      this.pmqsChart = debounce(() => {
        drawHengChart("pmqs", obj).resize();
      }, 100);
      window.addEventListener("resize", _this.pmqsChart, true);
    },
    // 区级订单核销率
    async getJfddhxl() {
      let _this = this;
      const res = await this.$axios.get(getJfddhxlDistrictUrl, {
        params: {
          selectLatitude: this.searchParam.hxlValue,
        },
      });
      if (res.code === 200 && res.success) {
        this.JfddhxlData = res.data;
        this.drawJfddhxl();
      }
    },
    drawJfddhxl() {
      let _this = this;
      // 核销率
      let obj = {
        x: [],
        y: [],
      };
      let seriesParams = {
        axisLabelFormatter: "{value}%",
        valueFormatter(value) {
          return value + "%";
        },
        seriesColor: "#FFCE6F",
        barWidth: "20%",
      };
      this.JfddhxlData.forEach((ele) => {
        obj.x.push(ele.tenantName);
        obj.y.push(ele.ratio ? ele.ratio : 0);
      });
      drawThirtyActivationChart("hxlPie", obj, seriesParams);
      if (!this.hxlChart) {
        this.hxlChart = debounce(() => {
          drawThirtyActivationChart("hxlPie", obj, seriesParams).resize();
        }, 100);
        window.addEventListener("resize", _this.hxlChart, true);
      }
    },
    // 核销率切换事件
    handleHxlChange() {
      this.getJfddhxl();
    },
    // 消费率
    async getPointXfl() {
      let _this = this;
      const res = await this.$axios.get(getPointXflDistrictUrl, {
        params: {
          selectLatitude: this.searchParam.xflValue,
        },
      });

      if (res.code === 200 && res.success) {
        this.PointXflData = res.data;
        this.draPointXfl();
      }
    },
    draPointXfl() {
      let _this = this;
      // 消费率
      let obj = {
        x: [],
        y: [],
      };
      let seriesParams = {
        axisLabelFormatter: "{value}%",
        valueFormatter(value) {
          return value + "%";
        },
        seriesColor: "#FF8C8C",
        barWidth: "20%",
      };
      this.PointXflData.forEach((ele) => {
        obj.x.push(ele.tenantName);
        obj.y.push(ele.ratio ? ele.ratio : 0);
      });
      drawThirtyActivationChart("xflPie", obj, seriesParams);
      if (!this.xflChart) {
        this.xflChart = debounce(() => {
          drawThirtyActivationChart("xflPie", obj, seriesParams).resize();
        }, 100);
        window.addEventListener("resize", _this.xflChart, true);
      }
    },
    // 消费率切换事件
    handleXflChange() {
      this.getPointXfl();
    },

    // 空间预约次数 - 选中变化
    kjyycsSelOnChange() {
      this.getkjyycsData();
    },
    async getkjyycsData() {
      let _this = this;
      let res = await this.$axios.get(getPlaceOrderDetailsDistrictUrl, {
        params: { selectLatitude: this.searchParam.kjyycs },
      });
      if (res && res.code === 200) {
        this.kjyycsData = res.data;
        this.drawkjyycs();
      }
    },
    drawkjyycs() {
      let _this = this;
      let obj = {
        x: [],
        y: [],
      };
      this.kjyycsData.forEach((ele, index) => {
        let oobj = {
          name: ele.tenantName,
          type: "line",
          data: [],
        };
        if (index == 0) {
          ele.list &&
            ele.list.forEach((Element) => {
              obj.x.push(Element.date);
            });
        }
        ele.list &&
          ele.list.forEach((Element) => {
            oobj.data.push(Element.count);
          });
        obj.y.push(oobj);
      });
      this.$nextTick(() => {
        drawAreahyrsLineChart("yycsLine", obj);
        if (!this.yycsChart) {
          this.yycsChart = debounce(() => {
            drawAreahyrsLineChart("yycsLine", obj).resize();
          }, 100);
          window.addEventListener("resize", _this.yycsChart, true);
        }
      });
    },
    handleAddPeopleChange() {},
    filterUnit(data) {
      if (!data) {
        return "";
      }
      return data.split("%")[0];
    },
  },
  destroyed() {
    let _this = this;
    window.removeEventListener("resize", _this.portraitEchart);
    window.removeEventListener("resize", _this.sbqkPieChart);
    window.removeEventListener("resize", _this.thirtyActivationEchart);
    window.removeEventListener("resize", _this.DaliyChart);
    window.removeEventListener("resize", _this.useNumberChart);
    window.removeEventListener("resize", _this.sjsbPieChart);
    window.removeEventListener("resize", _this.wclPieChart);
    window.removeEventListener("resize", _this.cmlPieChart);
    window.removeEventListener("resize", _this.sbqkChart);
    window.removeEventListener("resize", _this.djphChart);
    window.removeEventListener("resize", _this.pmqsChart);
    window.removeEventListener("resize", _this.hxlChart);
    window.removeEventListener("resize", _this.xflChart);
    window.removeEventListener("resize", _this.communityGenderChart);
    window.removeEventListener("resize", _this.addPeopleChart);
    window.removeEventListener("resize", _this.rhtjChart);
    window.removeEventListener("resize", _this.hyrsChart);
    window.removeEventListener("resize", _this.yycsChart);
  },
};
</script>
<style lang="less" scoped>
.mt20 {
  margin-top: 20px;
}

.opeDashboard {
  width: calc(100% + 40px);
  margin-left: -20px;
  box-sizing: border-box;
  height: calc(100% - 40px);
  padding: 20px 20px 20px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  // border: 1px solid red;

  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  // &::-webkit-scrollbar {
  //   width: 6px;
  //   height: 6px;
  //   background-color: #f5f5f5;
  // }

  // /*定义滚动条轨道 内阴影+圆角*/
  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  //   border-radius: 2px;
  //   background-color: #f5f5f5;
  // }

  // /*定义滑块 内阴影+圆角*/
  // &::-webkit-scrollbar-thumb {
  //   border-radius: 2px;
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  //   background-color: #f5f5f5;
  // }
  .tipTitle {
    display: inline-block;
    span {
      font-weight: 600;
      font-size: 20px;
      vertical-align: middle;
      color: rgba(0, 0, 0, 0.85);
    }
    img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-left: 8px;
    }
  }
  .title {
    font-weight: 600;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      max-width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 40px;
    }
  }

  .bg {
    background: #ffff;
    box-shadow: 0px 0px 8px 2px rgba(66, 114, 153, 0.08);
    border-radius: 15px;
  }

  // 基本信息
  &-msg {
    width: 100%;
    height: 230px;
    padding: 25px 43px 35px 29px;
    box-sizing: border-box;

    // 基本信息 上
    &-top {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;

      > div {
        box-sizing: border-box;
        width: calc((100% - 44px * 3) / 4);
        height: 122px;
        background: linear-gradient(180deg, #ddf2ff 0%, #ffffff 100%);
        border-radius: 8px;
        display: flex;
        padding: 0 20px;
        align-items: center;
        justify-content: space-between;

        img {
          width: 80px;
          height: 80px;
        }

        p {
          display: flex;
          flex-direction: column;

          span:nth-of-type(1) {
            font-weight: 600;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          span:nth-of-type(2) {
            font-weight: 400;
            font-size: 16px;
            color: #868686;
          }
        }
      }
    }

    // 基本信息 下
    &-bottom {
      margin-top: 20px;

      // 基本信息 下方第一个
      &-people {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 14px;

        > div {
          width: 100%;
          height: 150px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          border-radius: 12px;
          box-sizing: border-box;
          padding-left: 19px;

          > p {
            color: #ffffff;
            font-size: 14px;
          }

          > p:nth-of-type(1) {
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 5px;
          }

          > p:last-child {
            font-weight: 600;
            font-size: 28px;
            margin-top: 10px;
          }
        }

        div:nth-of-type(1) {
          background: url("./img/areaPeopleBg.png") no-repeat;
          background-size: 100% 100%;
        }

        div:nth-of-type(2) {
          margin-top: 10px;
          background: url("./img/areaHouseBg.png") no-repeat;
          background-size: 100% 100%;
        }
      }

      // 基本信息 下方第二个
      &-community {
        width: 100%;
        height: 310px;
        box-sizing: border-box;
        display: flex;

        > div:nth-of-type(1) {
          width: 40%;
        }

        #communityGender {
          width: 60%;
          height: 250px;
        }
      }

      // 基本信息 下方第三个
      &-pAndH {
        width: 100%;
        height: 310px;
        box-sizing: border-box;
        display: flex;

        &-text {
          width: 60%;

          &-content {
            width: 100%;

            > div {
              display: flex;
              align-items: center;

              img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
              }

              p:nth-of-type(1) {
                font-weight: 400;
                font-size: 16px;
                color: #868686;
                white-space: nowrap;
              }

              p:nth-of-type(2) {
                font-size: 14px;
                color: #868686;
              }

              p:nth-of-type(3) {
                font-weight: 600;
                font-size: 28px;
                color: #2ca9f5;
              }
            }

            > div:nth-of-type(1) {
              margin: 10px 0 50px 0;
            }
          }
        }

        &-echarts {
          width: 40%;
          #rfPie {
            height: 150px;
          }
        }
      }
    }
  }
  .opeDashboard-portrait {
    #oPortrait {
      height: 285px;
    }
  }
  .opeDashboard-areaPeopleRoom {
    #areaPeopleRoom {
      height: 285px;
    }
  }
  .opeDashboard-thirtyActivation {
    height: 305px;
    #thirtyActivation {
      height: 250px;
    }
  }
  // 专项运营
  &-zxyyMsg {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    height: 558px;

    &-content {
      width: 100%;
      box-sizing: border-box;
      margin-top: 20px;

      > div {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        img {
          width: 45px;
          height: 45px;
          margin-right: 10px;
        }

        p:nth-of-type(1) {
          font-weight: 600;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 8px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p:nth-of-type(2) {
          font-size: 14px;
          font-weight: 400;
          color: #868686;
        }
      }
    }
  }

  &-common {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 20px;
  }

  //使用人数
  &-useNumber {
    height: 305px;

    #useNumberEchart {
      width: 100%;
      height: 250px;
    }
  }

  // 新增人数
  &-addPeople {
    height: 305px;

    #addPeopleEchart {
      width: 100%;
      height: 250px;
    }
  }

  // 活跃人数
  &-hyrs {
    height: 305px;

    &-echarts {
      #hyrsLine {
        height: 250px;
      }
    }
  }

  // 沉默人数
  &-cmrs {
    height: 305px;

    #silentPie {
      height: 250px;
    }
  }

  // 日活统计
  &-rhtj {
    height: 305px;

    #rhtjLine {
      height: 250px;
    }
  }

  // 点击排行
  &-djph {
    height: 305px;

    #djph {
      height: 250px;
    }
  }

  // 商品兑换分析
  &-spdh {
    height: 305px;

    &-content {
      margin-top: 20px;

      > p {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        > span {
          display: inline-block;
          width: calc(100% / 3);
          text-align: center;
          flex-shrink: 0;

          span {
            display: inline-block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border-radius: 100%;
            background: rgba(44, 169, 245, 0.2);
            text-align: center;
            font-size: 12px;
            color: #2ca9f5;
            margin-top: -2px;
          }
          &:nth-child(1) {
            width: 44%;
          }
          &:nth-child(2) {
            width: 32%;
          }
          &:nth-child(3) {
            width: 24%;
          }
        }

        img {
          margin-top: -2px;
          vertical-align: middle;
          margin-right: 5px;
        }
        &.pbody {
          height: 24px;
          line-height: 24px;
          > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }
          > span:nth-child(3) {
            text-align: center;
          }
          > span:nth-child(2) {
            text-align: center;
            overflow: visible;
            i {
              text-align: left;
              font-style: normal;
              display: inline-block;
              width: 60px;
            }
          }
        }
      }

      > p:not(:first-child) {
        font-size: 14px;
        color: rgba(134, 134, 134, 0.85);
      }
    }
  }

  //积分订单核销率
  &-hxl {
    height: 305px;

    #hxlPie {
      height: 240px;
    }
  }

  //积分消费率
  &-xfl {
    height: 305px;

    #xflPie {
      height: 240px;
    }
  }

  //活动统计
  &-hdtj {
    height: 305px;
    .title {
      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 40px;
      }
      /deep/ .v-control {
        width: 100px !important;
        .el-select {
          width: 100% !important;
        }
      }
    }
    &-content {
      width: 100%;
      margin-top: 5px;
      &-top {
        margin-top: 20px;
        > div {
          box-sizing: border-box;
          width: calc((100% - 20px));
          height: 93px;
          background: rgba(255, 147, 52, 0.1);
          border-radius: 8px;
          border: 1px solid #ff9334;
          text-align: center;
          > p:nth-of-type(1) {
            font-size: 16px;
            color: #ff9334;
            margin-top: 14px;
          }
          > p:nth-of-type(2) {
            color: #ff9334;
            font-size: 18px;
            margin-top: 9px;
            span {
              font-weight: 700;
            }
          }
        }
      }

      &-bottom {
        margin-top: 20px;
        > div {
          box-sizing: border-box;
          width: calc((100% - 20px));
          height: 93px;
          background-size: 100% 100%;
          text-align: center;
          background: rgba(54, 209, 202, 0.1);
          border-radius: 8px;
          border: 1px solid #36d1ca;
          > p:nth-of-type(1) {
            font-size: 16px;
            color: #36d1ca;
            margin-top: 14px;
          }

          > p:nth-of-type(2) {
            color: #36d1ca;
            margin-top: 9px;
            font-size: 18px;
            span {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  // 预约次数
  &-yycs {
    height: 268px;

    #yycsLine {
      height: 200px;
    }
  }

  // 排名前三
  &-pmqs {
    height: 268px;

    #pmqs {
      height: 200px;
    }
  }

  // 排名末三
  &-pmms {
    height: 268px;
    position: relative;

    &-content {
      width: 100%;

      > div {
        width: 110px;
        height: 110px;
        border-radius: 100%;
        position: absolute;

        > p:nth-of-type(1) {
          text-align: center;
          font-weight: 400;
          font-size: 16px;
          margin-top: 23px;
        }

        > p:nth-of-type(2) {
          text-align: center;
          font-weight: 600;
          font-size: 18px;
          margin-top: 15px;
        }
      }

      > div:nth-of-type(1) {
        border: 1px solid #f88082;
        background: rgba(248, 128, 130, 0.1);
        top: 18%;
        left: 26%;

        p {
          color: #f88082;
        }
      }

      > div:nth-of-type(2) {
        border: 1px solid #2ca9f5;
        background: rgba(44, 169, 245, 0.1);
        top: 20%;
        left: 54%;

        p {
          color: #2ca9f5;
        }
      }

      > div:nth-of-type(3) {
        border: 1px solid #5f54fe;
        background: rgba(95, 84, 254, 0.1);
        bottom: 5%;
        left: 40%;

        p {
          color: #5f54fe;
        }
      }
    }
  }

  // 可预约空间数
  &-yykjs {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 268px;
    background: url("./img/areakyykjBg.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 20px;

    p:nth-of-type(1) {
      font-weight: 600;
      color: #ffffff;
      margin: 50px 0 0 48px;
      font-weight: 600;
      font-size: 36px;
      color: #ffffff;
      line-height: 50px;
    }
  }

  // 事件上报
  &-sjsb {
    height: 257px;

    #sjsbPie {
      height: 190px;
    }
  }

  // 近三十日事件完成率
  &-wcl {
    height: 257px;

    #wclPie {
      height: 190px;
    }
  }

  // 今日设备情况
  &-sbqk {
    height: 257px;

    &-content {
      width: 100%;

      #sbqkPie {
        height: 190px;
      }

      > div:nth-of-type(2) {
        box-sizing: border-box;
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #e1e1e1;

        img {
          width: 80px;
          height: 80px;
          margin-right: 25px;
        }

        p:nth-of-type(1) {
          font-size: 28px;
          font-weight: 600;
          color: #2ca9f5;
        }

        p:nth-of-type(2) {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #868686;
        }
      }
    }
  }
}

/deep/ .main-container-t {
  color: red;
}
.opeDashboard-yykjs {
  .title {
    display: flex;
    justify-content: space-around;
    white-space: nowrap;
    padding: 0 10px;
    span {
      font-weight: 600;
      font-size: 20px;
      color: #ffffff;
      line-height: 40px;
    }
    /deep/ .v-control {
      width: 100px !important;
      .el-select {
        width: 100% !important;
        .el-input__inner {
          background: none !important;
          border: 1px solid #fff !important;
          color: #fff !important;
        }
        .el-icon-arrow-up:before {
          color: #fff;
        }
      }
    }
  }
}
</style>
