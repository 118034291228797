import * as echarts from "echarts";

import "echarts-liquidfill";
import { values } from "lodash";
//近30日空间预约排名
export function drawHengChart(eleId, seriesData, seriesParams, max) {
  let option = {
    title: {
      text: "",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "5%",
      bottom: "3%",
      top: "8%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
      splitLine: {
        show: false, // 不显示网格线
      },
    },

    yAxis: [
      {
        type: "category",
        data: seriesData.x,
      },
      {
        type: "category",
        data: seriesData.y2,
        axisLine: {
          show: false, // 不显示坐标轴线
        },
        axisTick: {
          show: false, // 不显示坐标轴刻度线
        },
      },
    ],
    series: [
      {
        name: "2011",
        type: "bar",
        data: seriesData.y1,
        showBackground: true,
        barWidth: "30%",
        itemStyle: {
          color:
            seriesParams && seriesParams.seriesColor
              ? seriesParams.seriesColor
              : "#FFCE6F",
          borderRadius: [0, 20, 20, 0],
        },
        backgroundStyle: {
          color: "#F3F6F8",
          borderRadius: [0, 20, 20, 0],
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
//区级活跃度
export function drawAreaSilentChart(eleId, seriesData, max) {
  console.log(seriesData, "99");
  let totalNum = 0;
  if (seriesData && seriesData.y) {
    seriesData.y.forEach((element) => {
      if (element.value) {
        totalNum += Number(element.value);
      }
    });
  }
  let option = {
    tooltip: {
      trigger: "item",
    },
    title: {
      top: "center",
      text: ["{value|" + "沉默总人数" + "}", "{name|" + totalNum + "}"].join(
        "\n"
      ),
      left: "50%",
      textAlign: "center",
      textStyle: {
        rich: {
          value: {
            color: "#999",
            fontSize: 16,
          },
          name: {
            color: "#000",
            fontSize: 20,
            lineHeight: 28,
          },
        },
      },
    },

    series: [
      {
        name: "",
        type: "pie",
        radius: ["50%", "80%"],
        avoidLabelOverlap: false,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        data: seriesData.y,
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
//区级活跃度
export function drawAreahyrsLineChart(eleId, seriesData, max) {
  let option = {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      top: "8%",
      left: "3%",
      right: "4%",
      bottom: "5%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: seriesData.x,
    },
    yAxis: {
      type: "value",
    },
    series: seriesData.y,
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
//区级近30日活跃度
export function drawThirtyActivationChart(eleId, seriesData, seriesParams) {
  let option = {
    xAxis: {
      type: "category",
      data: seriesData.x,
    },
    yAxis: {
      type: "value",
      ...seriesParams.yAxisFommat,
      name: seriesParams.name ? seriesParams.name : "",
      axisLabel: {
        formatter: seriesParams.axisLabelFormatter
          ? seriesParams.axisLabelFormatter
          : "{value}",
        textStyle: {
          fontSize: "10",
        },
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow"
      },
      valueFormatter: (value) => {
        if (seriesParams.valueFormatter) {
          return seriesParams.valueFormatter(value);
        } else {
          return value;
        }
      },
    },
    grid: {
      top: "12%",
      left: "3%",
      right: "4%",
      bottom: "5%",
      containLabel: true,
    },
    series: [
      {
        data: seriesData.y,
        type: "bar",
        barWidth: seriesParams.barWidth ? seriesParams.barWidth : "50%",
        itemStyle: {
          color: seriesParams.seriesColor
            ? seriesParams.seriesColor
            : "#0A67FF",
          borderRadius: [20, 20, 0, 0],
        },
        emphasis: {
          focus: "series",
        },
        label: {
          show: false,
        },
        showBackground: true,
        backgroundStyle: {
          color: "#F3F6F8",
          borderRadius: [20, 20, 0, 0],
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
//区级新增注册人数
export function drawArearegisterNumberChart(eleId, seriesData, max) {
  let option = {
    title: {
      text: "",
      subtext: "",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        name: "新增注册人数",
        type: "pie",
        radius: ["0%", "90%"],
        data: seriesData,
        itemStyle: {
          normal: {
            // 设置阴影
            shadowBlur: 30,
            shadowColor: "rgba(147, 147, 147,0.3)",
            shadowOffsetX: 10,
            shadowOffsetY: 7,
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
//区级居民端使用人数
export function drawAreaUseNumberChart(eleId, seriesData, seriesParams, max) {
  let option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
      valueFormatter: (value) => {
        if (seriesParams.valueFormatter) {
          return seriesParams.valueFormatter(value);
        } else {
          return value;
        }
      },
    },

    grid: {
      top: "8%",
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: seriesData.x,
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: false, // 不显示网格线
      },
      axisLine: {
        show: true, // 不显示坐标轴线
      },
      axisTick: {
        show: true, // 不显示坐标轴刻度线
      },
      axisLabel: {
        formatter:
          seriesParams && seriesParams.axisLabelFormatter
            ? seriesParams.axisLabelFormatter
            : "{value}",
        textStyle: {
          fontSize: "10",
        },
      },
    },
    series: [
      {
        name: seriesParams && seriesParams.name1 ? seriesParams.name1 : "",
        type: "bar",
        stack: "total",
        barWidth: "30%",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#9BBC7B",
        },
        showBackground: true,
        backgroundStyle: {
          color: "#F3F6F8",
          borderRadius: [20, 20, 0, 0],
        },
        data: seriesData.y1,
      },
      {
        name: seriesParams && seriesParams.name2 ? seriesParams.name2 : "",
        type: "bar",
        stack: "total",
        label: {
          show: false,
        },
        itemStyle: {
          color: "#FFC600",
          borderRadius: [20, 20, 0, 0],
        },
        emphasis: {
          focus: "series",
        },
        data: seriesData.y2,
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
//区级人房数据
export function drawCommunityPeopleRoom(eleId, seriesData, seriesParams, max) {
  let option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      valueFormatter: (value) => {
        if (seriesParams.valueFormatter) {
          return seriesParams.valueFormatter(value);
        } else {
          return value;
        }
      },
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        data: seriesData.x,
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: seriesParams.axisLabelFormatter
            ? seriesParams.axisLabelFormatter
            : "{value}",
          textStyle: {
            fontSize: "12",
          },
        },
      },
    ],
    series: [
      {
        name: "注册房屋覆盖率",
        type: "bar",
        barWidth: "20%",
        label: {
          show: true,
          formatter: "{c}%",
          color: "#000",
        },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#3B69F2",
          borderRadius: [20, 20, 0, 0],
          fontSize: "6",
        },
        showBackground: true,
        backgroundStyle: {
          color: "#F3F6F8",
          borderRadius: [20, 20, 0, 0],
        },
        data: seriesData.y1,
      },
      {
        name: "人房匹配率",
        type: "bar",
        barWidth: "20%",
        label: {
          show: true,
          formatter: "{c}%",
          color: "#000",
        },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: "#B3DDD1",
          borderRadius: [20, 20, 0, 0],
          fontSize: "4",
        },
        showBackground: true,
        backgroundStyle: {
          color: "#F3F6F8",
          borderRadius: [20, 20, 0, 0],
        },
        data: seriesData.y2,
      },
    ],
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
//区级社区画像
export function drawCommunityPortrait(eleId, seriesData, max) {
  let option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },

    grid: {
      top: "8%",
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
    },
    yAxis: {
      type: "category",
      data: seriesData.x,
    },
    series: seriesData.y,
  };
  return drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 绘制图表
function drawedChart(eleId, option) {
  let chart = null;
  if (document.getElementById(eleId)) {
    chart = echarts.init(document.getElementById(eleId));
    chart.setOption(option);
  }
  return chart;
}
