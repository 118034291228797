import { color } from "echarts";

const arrPortrait = [
  {
    name: "3岁及以下",
    num: 1,
    type: "bar",
    stack: "total",
    label: {
      show: false,
    },
    emphasis: {
      focus: "series",
      label: {
        show: true,
        color: "#333",
      },
    },
    itemStyle: {
      color: "#3B69F2",
    },
    showBackground: true,
    backgroundStyle: {
      borderRadius: [0, 20, 20, 0],
      color: "#F3F6F8",
    },
    data: [],
  },
  {
    name: "4-17岁",
    type: "bar",
    num: 2,
    stack: "total",
    label: {
      show: false,
    },
    emphasis: {
      focus: "series",
      label: {
        show: true,
        color: "#333",
      },
    },
    itemStyle: {
      color: "#DFEEFF",
    },
    showBackground: true,
    backgroundStyle: {
      borderRadius: [0, 20, 20, 0],
      color: "#F3F6F8",
    },
    data: [],
  },
  {
    name: "18-59岁",
    type: "bar",
    num: 3,
    stack: "total",
    label: {
      show: false,
    },
    emphasis: {
      focus: "series",
      label: {
        show: true,
        color: "#333",
      },
    },
    itemStyle: {
      color: "#B3DDD1",
    },
    showBackground: true,
    backgroundStyle: {
      borderRadius: [0, 20, 20, 0],
      color: "#F3F6F8",
    },
    data: [],
  },
  {
    name: "60-69岁",
    type: "bar",
    num: 4,
    stack: "total",
    label: {
      show: false,
    },
    emphasis: {
      focus: "series",
      label: {
        show: true,
        color: "#333",
      },
    },
    itemStyle: {
      color: "#53DAAB",
    },
    showBackground: true,
    backgroundStyle: {
      borderRadius: [0, 20, 20, 0],
      color: "#F3F6F8",
    },
    data: [],
  },
  {
    name: "70-79岁",
    type: "bar",
    stack: "total",
    num: 5,
    label: {
      show: false,
    },
    emphasis: {
      focus: "series",
      label: {
        show: true,
        color: "#333",
      },
    },
    itemStyle: {
      color: "#FFC600",
    },
    showBackground: true,
    backgroundStyle: {
      borderRadius: [0, 20, 20, 0],
      color: "#F3F6F8",
    },
    data: [],
  },
  {
    name: "80岁以上",
    type: "bar",
    stack: "total",
    num: 6,
    label: {
      show: false,
    },
    emphasis: {
      focus: "series",
      label: {
        show: true,
        color: "#333",
      },
    },
    itemStyle: {
      color: "#FF8C8C",
    },
    showBackground: true,
    backgroundStyle: {
      borderRadius: [0, 20, 20, 0],
      color: "#F3F6F8",
    },
    data: [],
  },
];

export { arrPortrait };
